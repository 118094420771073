<template>
  <div>
    <Navbar page="Convidados" link="/treinamentos" nameLink="Gestão de Treinamentos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-6 rounded-lg px-1 py-2 ">
            <div class="grid grid-cols-12" v-if="resumo">
              <apexchart class="col-span-6" height="250" type="donut" :options="optionsGeral" :series="seriesGeral"></apexchart>

              <div class="col-span-6">
                <div class="flex flex-col mt-5 ml-4">
                  <p class="text-4xl" >{{ resumo.totalConfirmado }}</p>
                  <p class="font-bold" style="color: #008FFB">Participantes</p>
                </div>
    
                <div class="flex flex-row">
                  <div class="w-full flex">
                    <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" class="h-16">
                      <g id="color">
                        <path fill="#FFB923" d="M36,13.1161c-12.6823,0-23,10.3177-23,23c0,12.6822,10.3177,23,23,23c12.6822,0,23-10.3178,23-23 C59,23.4338,48.6822,13.1161,36,13.1161z"/>
                      </g>
                      <g id="hair"/>
                      <g id="skin"/>
                      <g id="skin-shadow"/>
                      <g id="line">
                        <circle cx="36" cy="36" r="23" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="2"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M26.5,44.1262c1.8768-1.5425,5.8239-2.494,10-2.4149c3.8343,0.0726,7.2926,1.0032,9,2.4149"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M21.5,27c1.1854-2.4206,3-5,9-4"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M50.5,27c-1.1854-2.4206-3-5-9-4"/>
                        <path d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31"/>
                        <path d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31"/>
                      </g>
                    </svg>
                    <!-- '#00E396', '#008FFB', '#FF4560' -->
                    <span class="text-sm  font-bold mt-6" style="color: #FF4560">{{ resumo.ruim ? ((resumo.ruim / resumo.totalFizeramProva) * 100).toFixed(1) : 0}} %</span>
                  </div>
                  <div class="w-full flex">
                    <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" class="h-16">
                      <g id="color">
                        <circle cx="36" cy="36" r="23" fill="#FFB923"/>
                        <path fill="#fff" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
                        <path fill="#ea5a47" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
                      </g>
                      <g id="hair"/>
                      <g id="skin"/>
                      <g id="skin-shadow"/>
                      <g id="line">
                        <circle cx="36" cy="36" r="23" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M31.6941,32.4036a4.7262,4.7262,0,0,0-8.6382,0"/>
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M48.9441,32.4036a4.7262,4.7262,0,0,0-8.6382,0"/>
                      </g>
                    </svg>
                    <span class="text-sm  font-bold mt-6" style="color: #008FFB">{{ resumo.bom ? ((resumo.bom / resumo.totalFizeramProva) * 100).toFixed(1) : 0}} %</span>
                  </div>
                  <div class="w-full flex">
                    <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" class="h-16">
                      <g id="color">
                        <circle cx="36" cy="36" r="23" fill="#FFB923"/>
                        <path fill="#D22F27" d="M26.4992,27.4384c-1.2653-3.3541-6.441-3.5687-6.1168,1.3178c0.0431,0.6485,0.281,1.2724,0.6414,1.8135 l5.3179,6.4224l0,0l5.2212-6.266c0.5796-0.6964,0.9224-1.5779,0.905-2.4853c-0.0863-4.3523-5.0509-4.0351-6.1274-0.8036"/>
                        <path fill="#D22F27" d="M45.8012,27.4384c-1.2547-3.3541-6.3873-3.5687-6.0658,1.3178c0.0428,0.6485,0.2787,1.2724,0.6361,1.8135 l5.2737,6.4224l0,0l5.1777-6.266c0.5747-0.6964,0.9147-1.5779,0.8974-2.4853c-0.0856-4.3523-5.0089-4.0351-6.0763-0.8036"/>
                        <path fill="#FFFFFF" d="M48.5859,42.6735c0,5.6296-4.1784,10.1046-12.5541,10.1046c-8.3738,0-12.6069-4.4888-12.6069-10.1047 C23.4249,42.6734,36.4503,45.7045,48.5859,42.6735z"/>
                      </g>
                      <g id="hair"/>
                      <g id="skin"/>
                      <g id="skin-shadow"/>
                      <g id="line">
                        <circle cx="36" cy="36" r="23" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M48.5859,42.6735c0,5.6296-4.1784,10.1046-12.5541,10.1046c-8.3738,0-12.6069-4.4888-12.6069-10.1047 C23.4249,42.6734,36.4503,45.7045,48.5859,42.6735z"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M26.4992,27.4384c-1.2653-3.3541-6.441-3.5687-6.1168,1.3178c0.0431,0.6485,0.281,1.2724,0.6414,1.8135l5.3179,6.4224l0,0 l5.2212-6.266c0.5796-0.6964,0.9224-1.5779,0.905-2.4853c-0.0863-4.3523-5.0509-4.0351-6.1274-0.8036 z"/>
                        <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M45.8012,27.4384c-1.2547-3.3541-6.3873-3.5687-6.0658,1.3178c0.0428,0.6485,0.2787,1.2724,0.6361,1.8135l5.2737,6.4224l0,0 l5.1777-6.266c0.5747-0.6964,0.9147-1.5779,0.8974-2.4853c-0.0856-4.3523-5.0089-4.0351-6.0763-0.8036 z"/>
                      </g>
                    </svg>
                  
                    <span class="text-sm  font-bold mt-6" style="color: #00E396">{{ resumo.otimo ? ((resumo.otimo / resumo.totalFizeramProva) * 100).toFixed(1) : 0}} %</span>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-full text-3xl font-bold ml-4" style="color: #FF4560">
                    {{  resumo.ruim }} <br>
                    <small class="block" style="font-size: 8px;">notas menores que 7</small>
                  </div>
                  <div class="w-full text-3xl font-bold ml-4" style="color: #008FFB">
                    {{  resumo.bom }} <br>
                    <small class="block" style="font-size: 8px;">notas entre 7 e 8</small>
                  </div>
                  <div class="w-full text-3xl font-bold ml-4" style="color: #00E396">
                    {{  resumo.otimo }} <br>
                    <small class="block" style="font-size: 8px;">notas maiores que 8</small>
                  </div>
                </div>

              </div>
            </div>

        </div>
        
        <div class="col-span-12 md:col-span-6 py-8 px-8 bg-gray-100" v-if="treinamento">
          <h2 class="text-2xl mb-3 font-semibold">Informações gerais</h2>
          <ul>
            <li>
              <b>Nome do evento:</b>  {{ treinamento.nome }}
            </li>
            <li>
              <b>Participantes confirmados / convidados:</b> {{  treinamento.presentes }} / {{  resumo.totalConvites }} 
            </li>
            <li>
              <b>Avaliações respondidas / enviadas:</b> {{ resumo.totalFizeramProva }} / {{ resumo.totalAvaliacoesEnviadas }} 
            </li>
            <li>
              <b>Média das avaliações:</b> {{ (resumo.mediaGeral*100).toFixed(0) }} 
            </li>
            <li class="opacity-20">
              <b>Cerficados recebidos / enviados:</b> 0 / 0 (em breve)
            </li>
          </ul>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-3">
            <label for="nome" class="block text-sm font-medium">Filtrar por:</label>
            <input v-model="nome" @keyup.enter="setPage(1)" type="text" name="nome" id="nome" placeholder="Nome ou email do convidado" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>

        <div class="flex flex-col md:col-span-3">
            <div>
                <label for="presencaconfirmada" class="block text-sm font-medium">
                    Presença confirmada
                </label>
                <select v-model="presencaconfirmada" name="presencaconfirmada" id="presencaconfirmada" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option default value="2">Ambos</option>
                    <option default value="1">Sim</option>
                    <option default value="0">Não</option>
                </select>
            </div> 
            <div>
                <label for="avaliacaorealizada" class="block text-sm font-medium">
                    Avaliação realizada
                </label>
                <select v-model="avaliacaorealizada" name="avaliacaorealizada" id="avaliacaorealizada" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option default value="2">Ambos</option>
                    <option default value="1">Sim</option>
                    <option default value="0">Não</option>
                </select>
            </div> 
            <div>
                <label for="certificadorecebido" class="block text-sm font-medium">
                    Certificado recebido
                </label>

                <select v-model="certificadorecebido" name="certificadorecebido" id="certificadorecebido" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option default value="2">Ambos</option>
                    <option default value="1">Sim</option>
                    <option default value="0">Não</option>
                </select>
            </div> 
        </div>
        
        <div class="col-span-12 md:col-span-3">
          <label class="block text-sm font-medium">&nbsp;</label>
          <button @click="setPage(1)" class="mt-1 bg-gray-900 hover:bg-gray-700 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <button @click="enviarConvites()" type="button"     class="text-sm text-white hover:bg-green-700 bg-green-600 rounded-full py-1 px-4 my-2 mr-3">
          Enviar convites para selecionados
      </button>

      <button @click="enviarAvaliacoes()" type="button"   class="text-sm text-white hover:bg-gray-800 bg-gray-700 rounded-full py-1 px-4 my-2 mr-3">
          Enviar Avaliações para os selecionados
      </button>

      <button @click="enviarCertificados()" type="button" class="text-sm text-white hover:bg-blue-800 bg-blue-700 rounded-full py-1 px-4 my-2 mr-3">
          Enviar certificado para selecionados
      </button>
      <button v-if="treinamento && treinamento.estatico" @click="editarnota = !editarnota" type="button" class="text-sm text-white hover:bg-yellow-800 bg-yellow-700 rounded-full py-1 px-4 my-2 mr-3">
          {{ editarnota ? 'Terminei de editar' : 'Editar notas'  }}
      </button>
                   
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-tight cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Participante
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Email
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Convite enviado
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Presença confirmada
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Avaliação enviada
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight ">
                        Avaliação realizada / nota
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight">
                        Certificado enviado
                      </th>
                      <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 tracking-tight w-128 min-w-full">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <input :checked="selecionados.indexOf(item._id) > -1" @change="(e) => selecionadosControl(item._id, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.nome ? item.nome : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                          <div class="text-xs 2xl:text-sm text-gray-500 break-words">
                            {{item.email ? item.email : ''}}
                          </div>

                          <button v-if="isUserRoot() || $store.state.user.consultor" @click="excluirRoot(item)" class="bg-red-500 text-white rounded-sm text-xs m-1 px-1" type="button">excluir (irreversível)</button>
                      </td>
                      <td class="px-2 py-3">
                        <div class="">
                          <div class="text-sm text-center text-gray-500" :class="item.conviteEnviado && item.conviteEnviado.enviado ? 'text-green-500': ''">

                            <span class="text-red-500" v-if="!item.conviteEnviado || !item.conviteEnviado.enviado">Não</span>
                            <span class="text-green-500" v-if="item.conviteEnviado && item.conviteEnviado.enviado">Sim</span>
                            <br>
                            <span v-if="item.conviteEnviado && item.conviteEnviado.data && item.conviteEnviado.data">
                              {{ item.conviteEnviado.data | moment("DD/MM/YYYY HH:mm:ss") }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="text-center">
                          <div class="text-sm text-gray-500 text-center" :class="item.presenca ? 'text-green-500': 'text-red-500'">

                            <span class="text-red-500" v-if="!item.presenca">Não</span>
                            <span class="text-green-500" v-if="item.presenca">Sim</span>
                            <br>
                            <span v-if="item.presencaDataConfirmacao">
                              {{ item.presencaDataConfirmacao | moment("DD/MM/YYYY HH:mm:ss") }} {{ item.confirmadoManual ? 'Confirmada Manualmente' : '' }}
                            </span>
                            <button v-if="(isUserRoot() || $store.state.user.consultor) && !item.confirmadoManual && !item.presencaDataConfirmacao" @click="confirmarRoot(item)" class="bg-red-500 text-white rounded-sm text-xs m-1 px-1" type="button">
                            confirmar manualmente
                            </button>
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="text-center">
                          <div class="text-sm text-gray-500 text-center" :class="item.presenca ? 'text-green-500': 'text-red-500'">

                            <span class="text-red-500" v-if="!item.prova || !item.prova.ativo">Não</span>
                            <span class="text-green-500" v-if="item.prova && item.prova.ativo">Sim</span>
                            <br>
                            <span v-if="item.prova && item.prova.data">
                              {{ item.prova.data | moment("DD/MM/YYYY HH:mm:ss") }}
                            </span>

                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.prova && item.prova.resultado">
                          <div class="text-sm text-gray-500 text-center">
                            <span class="text-sm font-medium" :class="item.prova.resultado.aprovado ? 'text-green-600' : 'text-red-600'" >
                                <span  v-show="item.prova.resultado.media <= 1 && !editarnota" :style="{color: +item.prova.resultado.media > 0.8 ? '#00E396' : +item.prova.resultado.media >= 0.7 ? '#008FFB' : '#FF4560'}">
                                  {{ item.prova.resultado.aprovado ? 
                                  `${parseFloat(+item.prova.resultado.media *100).toFixed(0)}` : 
                                  `${parseFloat(+item.prova.resultado.media *100).toFixed(0)}`
                                  }}
                                </span>
                                <input v-show="editarnota" @change="updateNota(item)" v-if="item.treinamento && item.treinamento.estatico" class="rounded-lg w-16 block mx-auto" type="number" v-model="item.prova.resultado.media">
                                <br>
                                <span v-if="item.prova && item.prova.dataAplicada">
                                  {{item.prova.dataAplicada | moment("DD/MM/YYYY HH:mm:ss") }}
                                </span>
                            </span> 
                          </div> 
                        </div>
                        <div v-else-if="item.prova && item.prova.esperandoCorrecao" class="text-sm text-yellow-500 text-center">
                          Esperando correção
                        </div>
                        <div v-else class="text-sm text-gray-500 text-center">
                          Não realizada
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="text-center" v-if="validaDataUpdate(item)">
                          <div  class="text-sm text-gray-500 text-center" :class="item.certificadoEnviado ? 'text-green-500': 'text-red-500'">
                            {{ item.certificadoEnviado && item.certificadoEnviado ? 'Sim' : 'Não'}}  <br>
                            <span v-if="item.certificadoEnviado">
                              {{ item.certificadoEnviado | moment("DD/MM/YYYY HH:mm:ss") }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3 grid grid-cols-2">
                        <button  @click="enviarConvite(item._id)" :disabled="item.treinamento.estatico" :class="{'opacity-20': item.treinamento.estatico}" type="button" class="col-span-2 2xl:col-span-1 w-full text-sm text-white hover:bg-green-700 bg-green-600 rounded-full mt-1 2xl:py-1 2xl:px-4 2xl:mt-2 2xl:mx-2">
                          Enviar Convite
                        </button>
                        <button  @click="realizarProva(item)" :disabled="item.treinamento.estatico" :class="{'opacity-20': item.treinamento.estatico}"  type="button" class="col-span-2 2xl:col-span-1 w-full text-sm text-white hover:bg-gray-900 bg-gray-700 rounded-full mt-1 2xl:py-1 2xl:px-4 2xl:mt-2 2xl:mx-2">
                          Enviar Avaliação
                        </button>
                        <a :target="item.prova && !item.treinamento.estatico ? '_blank' : '_self'" :href="item.prova && !item.treinamento.estatico ?`/treinamentos/answer/${item.prova._id}` : '#'" class="text-center col-span-2 2xl:col-span-1 w-full text-sm text-white hover:bg-yellow-600 bg-yellow-700 rounded-full mt-1 2xl:py-1 2xl:px-4 2xl:mt-2 2xl:mx-2" :class="{'opacity-20': !item.prova || item.treinamento.estatico}">
                          Visualizar prova
                        </a>
                        <button @click="enviarCertificado(item)" type="button" class="col-span-2 2xl:col-span-1 w-full text-sm text-white hover:bg-blue-600 bg-blue-700 rounded-full mt-1 2xl:py-1 2xl:px-4 2xl:mt-2 2xl:mx-2">
                          Enviar Certificado
                        </button>
                        <a @click="checkCertificado(item.certificadoEnviado)" :target="item.certificadoEnviado ? '_blank' : '_self'" :href="item.certificadoEnviado ? `${url_api}/certificado/${item._id}` : 'javascript:void(0)'" class="text-center col-span-2 2xl:col-span-1 w-full text-sm text-white hover:bg-yellow-600 bg-yellow-700 rounded-full mt-1 2xl:py-1 2xl:px-4 2xl:mt-2 2xl:mx-2" :class="{'opacity-20': !item.certificadoEnviado}">
                          Visualizar Certificado
                        </a>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <label for="perPage">Itens por página
              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </label>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      editarnota: false,
      route: 'treinamentos',
      list: [],
      selecionados: [],
      page: 1,
      perPage: 20,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },

      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Otimo", "Bom", "Ruim"],
        colors: ['#00E396', '#008FFB', '#FF4560'],
        legend: {
          show: false,
        }
      },
      seriesGeral: [0, 0, 0],
      treinamento: null,
      resumo: null,

      nome: '',
      presencaconfirmada: '2',
      avaliacaorealizada: '2',
      certificadorecebido: '2',
    }
  },
  methods: {
    checkCertificado(enviado) {
      if (!enviado) this.$vToastify.error("Certificado ainda não foi enviado.");
    },
    async excluirRoot(item) {
      if (confirm("Tem certeza? Não tem como reverter se excluir por esse botão")) {
        await this.$http.delete(`/v1/${this.route}/convidados/excluirConvidado/${item._id}`);
        this.start();
      }
    },
    async confirmarRoot(item) {
      if (confirm("Tem certeza? Não tem como reverter se confirmar a presença manual por esse botão")) {
        await this.$http.post(`/v1/${this.route}/convidados/confirmarRoot/${item._id}`);
        this.start();
      }
    },
    async updateNota(convidado) {
      if (convidado.prova.resultado.media > 10) {
        convidado.prova.resultado.media = convidado.prova.resultado.media / 100;
      }

      await this.$http.put(`/v1/${this.route}/convidados/updatenota`, { convidado });
      this.start();
    },
    validaDataUpdate(item) {
      //pode exibir se ainda nao fez prova
      if (!item.prova) return true;

      //pode exibir se ja fez prova e a data for maior que 17 de março, dia que subimos a feature nova.
      return moment(item.prova?.dataAplicada) > moment("2024-03-17");
    },
    async start() {
      this.query.limit = this.perPage;
      this.query.nome = this.nome;
      this.query.presencaconfirmada = this.presencaconfirmada;
      this.query.avaliacaorealizada = this.avaliacaorealizada;
      this.query.certificadorecebido = this.certificadorecebido;

      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      const listReq = await this.$http.post(`/v1/${this.route}/convidados/list`, { treinamento, ...this.query });

      this.list = listReq.data.data;
      this.total = listReq.data.total;

      const reqTreinamento = await this.$http.get(`/v1/treinamentos/${treinamento}`);
      this.treinamento = reqTreinamento.data;

      this.resumo = reqTreinamento.data.resumo;
      this.seriesGeral = [this.resumo.otimo, this.resumo.bom, this.resumo.ruim];
    },

    async enviarConvite(id) {
      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      const form = { _id: id, treinamento };

      const req = await this.$http.post(`/v1/${this.route}/convidados/enviarConvite`, form);
      if (req.data.success) {
          this.$vToastify.success("Enviado com sucesso!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async enviarCertificados() {
      if(!this.selecionados.length) return this.$vToastify.error("Selecione os convidados para enviar");

      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      const form = { _id: treinamento };
      form.selecionados = this.selecionados;

      const req = await this.$http.post(`/v1/${this.route}/convidados/certificadosEmail`, form);
      if (req.data.success) {
          this.$vToastify.success("Enviados com sucesso!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },
    async enviarAvaliacoes() {
      if(!this.selecionados.length) return this.$vToastify.error("Selecione os convidados para enviar as avaliações");

      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      const form = { treinamento: treinamento };
      form.selecionados = this.selecionados;

      const req = await this.$http.post(`/v1/avaliacoes/novaProvaEnviarEmails`, form);
      if (req.data.success) {
          this.$vToastify.success("Enviados com sucesso!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async enviarConvites() {
      if(!this.selecionados.length) return this.$vToastify.error("Selecione os convidados para enviar");

      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      const form = { _id: treinamento };
      form.selecionados = this.selecionados;

      const req = await this.$http.post(`/v1/${this.route}/convidados/enviarConvites`, form);
      if (req.data.success) {
          this.$vToastify.success("Enviados com sucesso!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async realizarProva(item) {
      const req = await this.$http.post(`/v1/avaliacoes/novaProvaEnviarEmail`,{ _id: item._id, treinamento: item.treinamento  });
      if(req.data.success) {
          this.$vToastify.success("Enviado com sucesso!");
          this.start();
      }else {
          this.$vToastify.error(req.data.err);
      }
    },

    async enviarCertificado(item) {
      const req = await this.$http.post(`/v1/${this.route}/convidados/certificadoEmail`,{ _id: item._id });
      if(req.data.success) {
          this.$vToastify.success("Enviado com sucesso!");
          this.start();
      }else {
          this.$vToastify.error(req.data.err);
      }
    },
    
    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.nome = this.nome;
      this.query.presencaconfirmada = this.presencaconfirmada;
      this.query.avaliacaorealizada = this.avaliacaorealizada;
      this.query.certificadorecebido = this.certificadorecebido;
      this.query.t = new Date().getTime();

      this.$router.push({ path: `/${this.route}/convidados/${this.$route.params.id}`, query: this.query });
    },

    async selecionarTodos(){
      const treinamento = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

      if (!this.selecionados.length) {
        const listReq = await this.$http.post(`/v1/${this.route}/convidados/list`, { all: true, treinamento });
        this.selecionados = listReq.data.data.map(el => el._id);
      } else {
        this.selecionados = [];
      }
    },
    selecionadosControl(id, e){
      if (this.selecionados.indexOf(id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.indexOf(id), 1);
      } else {
        this.selecionados.push(id);
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>